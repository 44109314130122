import './Cards.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

function Cards() {
    useEffect(() => {
        AOS.init();
    });

    const writeRunElements = document.querySelectorAll('.writeRun');
    writeRunElements.forEach(element => {
        const text = element.innerText;
        element.innerHTML = '';
        text.split('').forEach((char, index) => {
            const span = document.createElement('span');
            span.innerHTML = char === ' ' ? '&nbsp;' : char;
            span.style.animationDelay = `${0.1 * index}s`;
            element.appendChild(span);
        });
    });

     // Número de telefone para o WhatsApp
     const phoneNumber = '+555186529937';

     // Mensagens pré-definidas para cada link
     const mensagemLinkOne = 'Olá, vim pelo Website e estou interessado no Plano Mensal.';
     const mensagemLinkTwo = 'Olá, vim pelo Website e estou interessado no Plano Trimestral.';
     const mensagemLinkThree = 'Olá, vim pelo Website e estou interessado no Plano Semestral.';
 
     // Codificando as mensagens para serem usadas em URLs
     const mensagemCodificadaLinkOne = encodeURIComponent(mensagemLinkOne);
     const mensagemCodificadaLinkTwo = encodeURIComponent(mensagemLinkTwo);
     const mensagemCodificadaLinkThree = encodeURIComponent(mensagemLinkThree);
 
     // Criando os links do WhatsApp com o número e a mensagem para cada link
     const linkOne = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${mensagemCodificadaLinkOne}`;
     const linkTwo = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${mensagemCodificadaLinkTwo}`;
     const linkThree = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${mensagemCodificadaLinkThree}`;

  return (
    <section className="cards" id='cards'>
        <h2 data-aos="fade-up" data-aos-duration="2500">conheça nossas <b>melhores ofertas</b></h2>
        <p data-aos="fade-up" data-aos-duration="1500" className="writeRun">Encontre o plano perfeito que se encaixa nas suas necessidades e objetivos. Dê uma olhada nos nossos planos e descubra como podemos ajudá-lo a alcançar seus objetivos de forma eficaz e conveniente.</p>
        
        <p className='arrow-scroll'>
            <img src='../img/depoiments_arrow_right.png'/>
            <img src='../img/depoiments_arrow_right.png'/>
        </p>
        <div className='card-container'>
            <div className='card-single' data-aos="fade-up-right" data-aos-duration="2500">
                <div className='title'>
                    <div className='circle'>
                        <img src='../img/moeda_2.png' alt='Coin'></img>
                    </div>
                    <div className='have'>
                        <h2 className="writeRun">MENSAL</h2>
                    </div>
                </div>

                
                <ul className='description'>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">1 TELA FHD/HD/SD</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">+100 mil conteúdos</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">Canais Infantil</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">Canais de Esporte</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">Filmes, Séries e Novelas</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">Guia de programação EPG</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">ADULTOS (opcional)</h3>
                    </li>
                </ul>
                
                <a href={linkOne} target='_blank'><button>ASSINAR POR 29,99</button></a>
            </div>

            <div className='card-single' data-aos="zoom-in" data-aos-duration="2000">
                <div className='title'>
                    <div className='circle'>
                        <img src='../img/moeda_3.png' alt='Coin'></img>
                    </div>
                    <div className='have'>
                        <h2>TRIMESTRAL</h2>
                    </div>
                </div>

                
                <ul className='description'>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">1 TELA FHD/HD/SD</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">+100 mil conteúdos</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">Canais Infantil</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">Canais de Esporte</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">Filmes, Séries e Novelas</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">Guia de programação EPG</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">ADULTOS (opcional)</h3>
                    </li>
                </ul>
                
                <a href={linkTwo} target='_blank'><button>ASSINAR POR 79,99</button></a>
            </div>

            <div className='card-single' data-aos="fade-up-left" data-aos-duration="2500">
                <div className='title'>
                    <div className='circle'>
                        <img src='../img/moeda_4.png' alt='Coin'></img>
                    </div>
                    <div className='have'>
                        <h2>SEMESTRAL</h2>
                    </div>
                </div>

                
                <ul className='description'>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">1 TELA FHD/HD/SD</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">+100 mil conteúdos</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">Canais Infantil</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">Canais de Esporte</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">Filmes, Séries e Novelas</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">Guia de programação EPG</h3>
                    </li>
                    <li>
                        <img src='../img/check.png' alt='Check Icon'></img>
                        <h3 className="writeRun">ADULTOS (opcional)</h3>
                    </li>
                </ul>
                
                <a href={linkThree} target='_blank'><button>ASSINAR POR 149,99</button></a>
            </div>
        </div>
        
        <b>Venha conhecer nossa ampla seleção de filmes e encontre suas próximas grandes aventuras. Assine para começar a assistir hoje!</b>
    </section>
  );
}

export default Cards;
