import React, { useRef, useState, useEffect, useMemo } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './About.css';

// Componente para o Card One
const CardOne = ({ imagem }) => (
    <div className="card-one" data-aos="fade-left" data-aos-duration="3000">
        <img src={imagem} alt='Kung Fu Panda 4' />
    </div>
);

// Componente para o Card Two
const CardTwo = ({ imagem }) => (
    <div className="card-two" data-aos="fade-right" data-aos-duration="3000">
        <img src={imagem} alt='Bob Marley 2024' />
    </div>
);

function About() {
    useEffect(() => {
        AOS.init();
        const writeRunElements = document.querySelectorAll('.writeRun');
        writeRunElements.forEach(element => {
            const text = element.innerText;
            element.innerHTML = '';
            let totalLetters = 0;
            text.split(' ').forEach((word, index) => {
                const wordContainer = document.createElement('span');
                wordContainer.style.display = 'inline'; 
                wordContainer.style.whiteSpace = 'nowrap'; 
                element.appendChild(wordContainer);
                word.split('').forEach((char, index) => {
                    const charSpan = document.createElement('span');
                    charSpan.innerHTML = char === ' ' ? '&nbsp;' : char;
                    const delay = 0.1 * (totalLetters + index);
                    charSpan.style.animationDelay = `${delay}s`; // Aplica o delay
                    wordContainer.appendChild(charSpan);
                });
                totalLetters += word.length;
                element.appendChild(document.createTextNode(' '));
                // Adiciona uma classe para controle da animação
                wordContainer.classList.add('word');
            });
        });

        // Iniciar a troca de imagens e a animação de fade a cada 3 segundos
        const interval = setInterval(() => {
            setNextImage(prevIndex => (prevIndex + 1) % imagensCardOne.length);
        }, 4000);

        // Limpar o intervalo quando o componente for desmontado
        return () => clearInterval(interval);
    }, []);

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [nextImageIndex, setNextImageIndex] = useState(0); // Índice da próxima imagem na array

    // Array de imagens para card-one e card-two
    const imagensCardOne = useMemo(() => ['../img/banner-left-panda.png', '../img/banner-left-avatar-movie.png', '../img/banner-left-mario.png'], []);
    const imagensCardTwo = useMemo(() => ['../img/banner-right-bob.png', '../img/banner-right-aquaman.png', '../img/banner-right-avatar.png'], []);

    // Estado para controlar as imagens exibidas
    const [imagemCardOne, setImagemCardOne] = useState(imagensCardOne[0]);
    const [imagemCardTwo, setImagemCardTwo] = useState(imagensCardTwo[0]);

    const togglePlay = () => {
        const video = videoRef.current;

        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
        setIsPlaying(!isPlaying);
    };

    // Função para alternar para a próxima imagem na array
    const setNextImage = () => {
        setNextImageIndex(prevIndex => (prevIndex + 1) % imagensCardOne.length);
        AOS.refresh();
    };

    useEffect(() => {
        setImagemCardOne(imagensCardOne[nextImageIndex]);
        setImagemCardTwo(imagensCardTwo[nextImageIndex]);
    }, [nextImageIndex, imagensCardOne, imagensCardTwo, setImagemCardOne, setImagemCardTwo]);

     // Número de telefone para o WhatsApp
     const phoneNumber = '+555186529937';

     // Mensagem pré-definida para o link
     const mensagemlinkNow = 'Olá, vim pelo Website e tenho interesse em Assinar Agora!';
 
     // Codificando a mensagem para ser usada em URLs
     const mensagemCodificadalinkNow = encodeURIComponent(mensagemlinkNow);
 
     // Criando o link do WhatsApp com o número e a mensagem
     const linkNow = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${mensagemCodificadalinkNow}`;

    return (
        <>
            <section className="about" id='about'>
                <div>
                    <CardOne imagem={imagemCardOne} key={imagemCardOne} />
                    <CardTwo imagem={imagemCardTwo} key={imagemCardTwo} />
                </div>
                <div>
                    <h2 data-aos="fade-left" data-aos-duration="3000">Explore uma <b>vasta seleção </b> <br />
                        de filmes emocionantes!</h2>
                    <p className="writeRun">Desfrute de uma variedade ilimitada de conteúdo, acesso sob demanda, sem comerciais irritantes e recomendações personalizadas ao assinar nossa plataforma de streaming. Assista quando e onde quiser, com preços acessíveis e atualizações constantes de novos títulos. Experimente uma nova era de entretenimento hoje mesmo!</p>
                </div>
            </section>

            <section className='services'>
                <div className='title'>
                    <h2 data-aos="fade-right" data-aos-duration="1500"><b>Acesse os melhores </b> lançamentos de 2023!</h2>
                    <p className="writeRun">Explore nosso vasto catálogo de entretenimento! Encontre seus filmes e séries favoritos, descubra novos lançamentos, desfrute de conteúdo exclusivo e mergulhe em histórias envolventes que vão te prender do início ao fim. Com atualizações regulares, sempre há algo emocionante para assistir.</p>
                    <p><b>Venha conhecer nossa ampla seleção de filmes e encontre suas próximas grandes aventuras. Assine para começar a assistir hoje!</b></p>

                    <div className='cta'>
                        <img src='../img/button_avatar.png' alt='Avatar Hass Streaming' data-aos="fade-up" data-aos-duration="3000" />
                        <a href={linkNow} target="_blank"><button>ASSINAR AGORA</button></a>
                    </div>
                </div>

                <div onClick={togglePlay} className='notebook' data-aos="fade-up" data-aos-duration="3000">
                    <video ref={videoRef} src='../video/notebook.mp4' muted />
                    <img src='../img/notebook.png' alt='notebook' />

                    {!isPlaying && (
                        <img className='play' src='../img/button_play.png' alt='Button Play' />
                    )}
                </div>
            </section>
        </>
    );
}

export default About;
