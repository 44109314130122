import React from "react";
import { Link } from "react-scroll";
import './Header.css';

function Header() {

    function toogleMobile() {
        var menuList = document.querySelector('.menu-mobile');

        menuList.style.display = menuList.style.display === "none" ? "block" : "none";

    }

    // Número de telefone para o WhatsApp
    const phoneNumber = '+555186529937';

    // Mensagem pré-definida
    const mensagem = 'Olá, vim pelo Website e gostaria de um Teste Gratuito.';

    // Codificando a mensagem para ser usada em um URL
    const mensagemCodificada = encodeURIComponent(mensagem);

    // Criando o link do WhatsApp com o número e a mensagem
    const whatsTest = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${mensagemCodificada}`;

    return (
        <header>
            <nav className="navbar">
                <div className="logo-marca">
                    <img src="img/logoTipo.png" alt="Hass Streaming"></img>
                </div>
                <ul className="menu">
                    <li><Link activeClass="active" to="hero" spy={true} smooth={true} duration={500}>Início</Link></li>
                    <li><Link activeClass="active" to="about" spy={true} smooth={true} duration={500}>Benefícios</Link></li>
                    <li><Link activeClass="active" to="cards" spy={true} smooth={true} duration={500}>Planos</Link></li>
                    <li><Link activeClass="active" to="faq" spy={true} smooth={true} duration={500}>Dúvidas</Link></li>
                </ul>
                <a href={whatsTest} target="_blank"><button><h2>Teste Grátis</h2></button></a>

            </nav>

            <div className='menu-mobile-btn'>
                <h2 onClick={toogleMobile}>Menu</h2>
                <ul className="menu-mobile">
                    <li><Link activeClass="active" to="hero" spy={true} smooth={true} duration={500}>Início</Link></li>
                    <hr/>
                    <li><Link activeClass="active" to="about" spy={true} smooth={true} duration={500}>Benefícios</Link></li>
                    <hr/>
                    <li><Link activeClass="active" to="cards" spy={true} smooth={true} duration={500}>Planos</Link></li>
                    <hr/>
                    <li><Link activeClass="active" to="faq" spy={true} smooth={true} duration={500}>Dúvidas</Link></li>
                </ul>
            </div>
        </header>
    );
}

export default Header;